h2{
    color: #427cab;
    text-align: center;
  }

.hoverImageBox {
    position: relative;
    cursor: pointer;
    object-fit: 'cover';
    &:hover img {
      opacity: 0.7;
    }
  
    &:hover svg {
      opacity: 1;
    }
  }


  .youTubeIconStyled {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    opacity: 0;
    transition: opacity 0.6s;
  }
  