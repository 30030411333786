.box {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  align-items: center;

  .container {
    padding: 3%;
    margin: 20px auto;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);
    border-radius: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h2 {
    color: #427cab;
    text-align: center;
  }
  .image-preview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
    justify-content: center;
    margin-top: 20px;

    .image-item {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 10px;
      justify-content: center;
      margin-top: 20px;
    }

    .gallery {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 10px;
      justify-content: center;
      margin-top: 20px;

      .image-item {
        max-width: 80%;

        img {
          width: 100%;
        }
      }

      .gallery-UploadImage-container {
        position: relative;
        width: 100%;
        height: 300px;
        border: 2px dashed #ccc;
        display: flex;
        justify-content: center;
        align-items: center;


        .gallery-UploadImage-preview {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }

        .gallery-UploadImage-input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }

        .gallery-image-item {
          position: relative;
        }

        .gallery-image-item img {
          width: 100%;
          height: auto;
          display: block;
        }

        .image-overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 10px;
          color: #fff;
          transition: opacity 0.3s ease;
          opacity: 0;
        }

        .image-item:hover .image-overlay {
          opacity: 1;
        }

        .remove-button {
          border: none;
          background-color: transparent;
          color: #fff;
          cursor: pointer;
          margin-top: 5px;
        }

        .remove-button:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .UploadImage-input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .btn {
    display: flex;
    justify-content: center;

    button {
      background-color: #427cab;
    }
  }
}