.image-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image-item {
  width: calc(50% - 20px);
  height: 300px;
  border: 2px dashed #ccc;
  box-sizing: border-box;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.single-image {
  .image-item {
    width: 100%;
    }
  }