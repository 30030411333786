.box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  align-items: center;

  .container {
    padding: 3%;
    margin: 20px auto;
    width: 90%;
    max-width: 800px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.2);
    border-radius: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .formControle {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  h2{
    color: #427cab;
    text-align: center;
  }

  .btn{
    display: flex;
    justify-content: center;
    Button{
      background-color: #427cab;
    }
  }
}