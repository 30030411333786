.container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  max-height: 80%;
  overflow: auto;
  .modal{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    .close_Icon{
      color: #427cab;
      cursor: pointer;
      margin-bottom: 0.5rem;
    }

  }
  #modal-title{
    color: #427cab;
    text-align: center;
    margin-bottom: 1rem;
  }
  .formControle{
    margin-left: 50px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  .field{
    height: 50px;
  }
  .btn{
    display: flex;
    margin-left: 80px;
    justify-content: center;
    Button{
      background-color: #427cab;
    }
  }

}
