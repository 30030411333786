.image-item {
  position: relative;
  display:inline-block;

  .delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

}